import { render, staticRenderFns } from "./ContactButton.vue?vue&type=template&id=2a113236&scoped=true&"
import script from "./ContactButton.vue?vue&type=script&lang=js&"
export * from "./ContactButton.vue?vue&type=script&lang=js&"
import style0 from "./ContactButton.vue?vue&type=style&index=0&id=2a113236&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a113236",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/builds/reifen_gundlach/frontend/components/atoms/Icon/Icon.vue').default})
