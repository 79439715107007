//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref, onMounted, onUnmounted } from '@nuxtjs/composition-api'

export default defineComponent({
  setup () {
    const showContactButton = ref(false)
    const showLabel = ref(false)
    const component = ref(null)

    function scrollToFooter () {
      component.value.scrollIntoView({ block: 'center', behavior: 'smooth' })
    }

    function onScroll () {
      const rect = component.value.getBoundingClientRect()
      const isInViewport = rect.top >= 0 && rect.bottom <= window.innerHeight

      showContactButton.value = !isInViewport
    }

    onMounted(() => {
      onScroll()
      window.addEventListener('scroll', onScroll, { passive: true })
      window.addEventListener('resize', onScroll, { passive: true })
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', onScroll, { passive: true })
      window.removeEventListener('resize', onScroll, { passive: true })
    })

    return {
      scrollToFooter,
      showContactButton,
      showLabel,
      component
    }
  }
})
